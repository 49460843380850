<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card>
     
      <div slot="header">
        <span class="title">新增關係</span>
      </div>
      
      <!--關係代碼＆名稱1-->
      <el-form ref="form" :model="relation" label-width="100px">
         <el-form-item label="關係代碼 1" prop="relationCode" class="fontDetail">
               <el-input
                v-model="relation.relationCode"
                placeholder="請輸入關係代碼"
                style="width: 50%"               
              ></el-input>
          </el-form-item>


          <el-form-item label="關係名稱 1" prop="relationName" class="fontDetail">
               <el-input
                v-model="relation.relationName"
                placeholder="請輸入關係名稱"
                 style="width: 50%"
              ></el-input>
          </el-form-item>


         <!--關係代碼＆名稱2, 若勾選「同上」,則唯讀-->
          <el-form-item label="關係代碼 2" prop="relationCode" class="fontDetail">
            <el-checkbox  v-model="checked" @change="handleCheck">同上</el-checkbox>
           
              <el-input
                 v-model="relation.connectRelationCode"
                 placeholder="請輸入關係代碼"
                 style="width: 40%"
                 class="dis"
                 :readonly="checked" 
                ></el-input>
            </el-form-item>
           
         
           <el-form-item label="關係名稱 2" prop="relationName" class="fontDetail">
               <el-input
                v-model="relation.connectRelationName"
                placeholder="請輸入關係名稱"
                style="width: 50%"
                :readonly="checked"
              ></el-input>
          </el-form-item>

         <!--新增活動＿底部按鈕-->
            <el-form-item>
            <div class="dialog-footer">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" class="btnDist" @click="add">新增</el-button>
            </div>
            </el-form-item>

      </el-form>

    </el-card>
  
  </div>
</template>

<script>
import API from "../../api"
import { Message } from "element-ui"
import Utils from "../../utils"
import Vue from "vue"

export default {
  name: "RelationAdd",
  props: {
    id: String,
    clickTime: Date,
  },
  
  data() {
     return {
        isLoading: false,
        relation: {
            relationCode: "",
            relationName: "",
            connectRelationCode: "",
            connectRelationName: "",
        },
        checked: false,
        
     }
  },

   watch: {
    clickTime: function (newVal) {
        this.openWindow()
      },
      'relation.relationCode1': function (newVal) {
        if(this.checked == true){
          this.relation.connectRelationCode = newVal 
        }
      },
      'relation.relationName1': function (newVal) {
        if(this.checked == true){
          this.relation.connectRelationName = newVal   
        }
      },
    },
    created() {
        this.openWindow()
    },

    methods: {
        openWindow(){
          if (this.$refs["form"]) {
              this.$refs["form"].resetFields() //按新增鍵後, 新增頁面清空
            }

            this.relation.relationCode = ""
            this.relation.relationName = ""
            this.checked = false
            this.relation.connectRelationCode = ""
            this.relation.connectRelationName = ""
          }, //新增頁面跳出, 不管是否建立新關係, 頁面都是清空

        //按鈕: 新增取消
        cancel() {
            this.$emit("add-finish", null)
        },

        //按鈕: 新增, 新增預設為「停用」
        add() {
          this.$refs["form"].validate((valid) => {
            if (valid) {
              API.relation.addRelation(this.relation).then((newP) => {
                Message({
                  message: "新增成功!",
                  type: "success",
                })
                this.$emit("add-finish", newP.data)
                this.$refs["form"].resetFields() //設定新增完後, 此新增頁面清除
              })
            }
          })
        },

        //同上：複製以上關係代碼與關係名稱且無法編輯
        handleCheck(value){
          if(value){
             this.relation.connectRelationCode = this.relation.relationCode,
             this.relation.connectRelationName = this.relation.relationName
          }else{
            this.relation.connectRelationCode = "",
            this.relation.connectRelationName = ""
          }
           
        },
            
        
    }

}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
}

.fontDetail {
  margin-left: 35px;
  font-weight: 520;
  font-size: 10px;
  color: #585858;
  margin-bottom: 10px;
}

/*頁面最底按鈕位置*/
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

/*頁面最底按鈕間距離*/
.btnDist {
  margin-left: 20px;
}

.dis{
  margin-left: 10px;
}
</style>