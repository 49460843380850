<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card>
     
      <div slot="header">
        <span class="title">編輯關係</span>
      </div>

      <!--關係代碼＆名稱1-->
      <el-form ref="form" :model="relation" label-width="100px">
         <el-form-item label="關係代碼 1" prop="relationCode" class="fontDetail">
               <el-input
                v-model="relation.relationCode"
                placeholder="請輸入關係代碼"
                style="width: 50%"
                disabled
              ></el-input>
          </el-form-item>


          <el-form-item label="關係名稱 1" prop="relationName" class="fontDetail">
               <el-input
                v-model="relation.relationName"
                placeholder="請輸入關係名稱"
                 style="width: 50%"
              ></el-input>
          </el-form-item>


         <!--關係代碼＆名稱2, 若勾選「同上」,則唯讀-->
          <el-form-item label="關係代碼 2" prop="relationCode" class="fontDetail">
                <el-input
                 v-model="relation.connectRelationCode"
                 placeholder="請輸入關係代碼"
                 style="width: 50%"
                 disabled
                ></el-input>
            </el-form-item>
           
         
           <el-form-item label="關係名稱 2" prop="relationName" class="fontDetail">
              
               <el-input
                v-model="relation.connectRelationName"
                placeholder="請輸入關係名稱"
                style="width: 50%"
                
              ></el-input>
          </el-form-item>

         <!--編輯活動＿底部按鈕-->
            <el-form-item>
            <div class="dialog-footer">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" class="btnDist" @click="edit">儲存</el-button>
            </div>
            </el-form-item>

      </el-form>

    </el-card>
  
  </div>
</template>

<script>
import API from "../../api"
import { Message } from "element-ui"
import Utils from "../../utils"
import Vue from "vue"

export default {
  name: "RelationEdit",
  props: {
    id: String,
    clickTime: Date,
  },
  
  data() {
     return {
        isLoading: false,
        relation: {
            relationName: "",
            connectRelationCode: "",
            connectRelationName: "",
        },
         checked: false,
     }
  },

   watch: {
    clickTime: function (newVal) {
        this.openWindow()
      },
     
    },
    created() {
        this.openWindow()
    },

    methods: {
        openWindow(){
           this.payerRelationId(this.id)
        },

        payerRelationId(id) {
          if (id == null) return;
          this.isLoading = true;
          API.relation
          .show(id).then((res) => {
             this.relation = res.data;
             this.isLoading = false;
          });
        },

        //按鈕: 編輯取消
        cancel() {
            this.$emit("update-finish", null)
        },

        //按鈕: 儲存
        edit() {
          this.$refs["form"].validate((valid) => {
            if (valid) {
              API.relation
                .updateRelation(this.id, this.relation)
                .then((newP) => {
                  Message({
                    message: "更新成功!",
                    type: "success",
                  })
                  this.$emit("update-finish", newP.data)
                })
                .catch((err) => {
                  // 1. loading 結束
                  this.isLoading = false
                })
            }
          })
        },
          
     
    }

}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
}

.fontDetail {
  margin-left: 35px;
  font-weight: 520;
  font-size: 10px;
  color: #585858;
  margin-bottom: 10px;
}

/*頁面最底按鈕位置*/
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

/*頁面最底按鈕間距離*/
.btnDist {
  margin-left: 20px;
}


</style>