<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card>
       
        <div slot="header" class="clearfix" style="line-height: 40.5px">
            <span class="title">關係管理</span>
            <el-button style="float: right" type="primary" @click="add">新增</el-button>
        </div>

        <el-table
            :data="tableData"
            border
            stripe
            empty-text="暫無數據"
            style="width: 100%"
        >

        <!--關係名稱 1-->
            <el-table-column
                fixed
                prop="relationName"
                label="關係名稱 1"
                min-width="300"
                align="center"
                >
            </el-table-column>
        <!--關係名稱 2-->
            <el-table-column
                prop="connectRelationName"
                label="關係名稱 2"
                width="300"
                align="center"
                >
            </el-table-column>
        <!--狀態-->
            <el-table-column
                prop="status"
                label="狀態"
                width="300"
                align="center"
                >
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 'OPEN'" style="color: #7ebe50">啟用</span>
                    <span v-else style="color: #e57470">停用</span>
                </template>
            </el-table-column>
        <!--操作-->
            <el-table-column label="操作" width="290" align="center">
                <template slot-scope="scope">
                    <el-button
                    size="small"
                    type="primary"
                    v-on:click="edit(scope.row.id)"
                    >
                    編輯
                    </el-button>
                    <el-button
                    v-if="scope.row.status == 'OPEN'"
                    size="small"
                    v-on:click="statusClose(scope.row.id)"
                    type="danger"
                    >停用</el-button
                    >
                    <el-button
                    v-if="scope.row.status == 'CLOSE'"
                    size="small"
                    v-on:click="statusOpen(scope.row.id)"
                    type="info"
                    >啟用</el-button
                    >
                    <el-button
                    v-if="scope.row.publishDatetime == null"
                    v-on:click="publishEdit(scope.row.id)"
                    size="small"
                    type="warning"
                    >發布</el-button
                    >
                    <el-button
                    v-else-if="scope.row.publishDatetime != null"
                    size="small"
                    type="info"
                    disabled
                    >已發布</el-button
                    >
                </template>
        </el-table-column>
        </el-table>


    </el-card>

    <!--編輯_關係畫面-->
    <el-dialog
      :visible.sync="dialogUse.editVisible"
      width="50%"
      top="20px"
      append-to-body
      :close-on-click-modal="false"
      @closed="closeDialog('edit')"
    >
      <RelationEdit
        :clickTime="dialogUse.clickTimeEdit"
        :id="dialogUse.editId"
        @update-finish="updateFinish"
      />
    </el-dialog>

    <!--新增_關係畫面-->
    <el-dialog
      :visible.sync="dialogUse.addVisible"
      width="50%"
      top="20px"
      append-to-body
      :close-on-click-modal="false"
      @closed="closeDialog('add')"
    >
      <RelationAdd
        :clickTime="dialogUse.clickTimeAdd"
        :id="dialogUse.addId"
        @add-finish="addFinish"
      />
    </el-dialog>

   </div>
</template>

<script>
import API from "../../api"
import Utils from "../../utils"
import Vue from "vue"
import RelationAdd from "./RelationAdd"
import RelationEdit from "./RelationEdit"

export default {
  name: "RelationManage",
  components: { RelationAdd, RelationEdit },

  data() {
    return {
    isLoading: false,
    tableData: [],
    dialogUse: {
        editVisible: false,
        editId: "",
        clickTimeEdit: null,
        addVisible: false,
        addId: "",
        clickTimeAdd: null,   
      },
    }
  },

    created() {
        this.openWindow()
    },

   methods: {
      openWindow() {
        this.query()
      },

      //取得關係
      query(){
        this.isLoading = true
         API.relation
        .query({ queryData: { companyId: this.id } })
        .then((res) => {
          this.tableData = res.data
          this.totalCount = res.data.length
          this.isLoading = false
        })
        .catch((e) => {
          console.log(`payerConnect show error`)
          this.$message.error("查詢失敗!")
          this.isLoading = false
        })
      },
    
       //新增關係
       add(id) {
        this.dialogUse.addId = id
        this.dialogUse.addVisible = true
        this.dialogUse.clickTimeAdd = new Date()
       },

       //編輯關係
       edit(id) {
        this.dialogUse.editId = id
        this.dialogUse.editVisible = true
        this.dialogUse.clickTimeEdit = new Date()
        },

        //啟用
       statusOpen(id){
        this.$confirm("您確定啟用此關係類型 ？", "提示", {
                dangerouslyUseHTMLString: true,
                confirmButtonText: "確定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                API.relation
                .openRelation(id)
                .then((res) => {
                    this.$message({
                    type: "success",
                    message: "此關係已啟用!",
                    })
                    this.query()
                })
                .catch((err) => {
                    // 1. loading 結束
                    this.isLoading = false
                })
            }) 
            .catch((err) => {
                    // 1. loading 結束
                    this.isLoading = false
                })
       },

        //停用
       statusClose(id){
        const status = this.tableData.filter(open => open.status == 'OPEN')
        if(status.length <= 2){
            this.$message({
                type: 'warning',
                message: "至少要有一組關係為「啟用」"
            })
            return
        }else{
            this.$confirm("您確定要停用此關係類型 ？<br>客戶那邊將不會出現此關係類型。", "提示", {
                dangerouslyUseHTMLString: true,
                confirmButtonText: "確定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                API.relation
                .closeRelation(id)
                .then((res) => {
                    this.$message({
                    type: "success",
                    message: "此關係已取消!",
                    })
                    this.query()
                })
                .catch((err) => {
                    // 1. loading 結束
                    this.isLoading = false
                })
            }) 
            .catch((err) => {
                    // 1. loading 結束
                    this.isLoading = false
                })
        } 
          
            
    },
   
       

        //發布
       publishEdit(id){
        this.$confirm("確定執行發布 ？", "提示", {
                dangerouslyUseHTMLString: true,
                confirmButtonText: "確定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                API.relation
                .publishToAllCompany(id)
                .then((res) => {
                    this.$message({
                    type: "success",
                    message: "已發布!",
                    })
                    this.query()
                })
                .catch((err) => {
                    // 1. loading 結束
                    this.isLoading = false
                })
            }) 
            .catch((err) => {
                    // 1. loading 結束
                    this.isLoading = false
                })
       },

      


        //新增關係完成
        addFinish(newData) {
            this.query()
            this.closeDialog("add")
        },

        //編輯關係完成
        updateFinish(newData) {
            this.query()
            this.closeDialog("edit")
        },

        //關閉彈窗(新增/編輯)
           closeDialog(type) {
            switch (type) {
              case "edit":
                this.dialogUse.editVisible = false
                this.dialogUse.editId = null
                break
              case "add":
                this.dialogUse.addVisible = false
                this.dialogUse.addId = null
                break
            }
    },

   }

}


</script>


<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}




</style>